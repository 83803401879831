import React from 'react'

export default function Intro() {
  return (
    <div className="wrap py-16">
        <div className="img-wrap">
            <img src="/asset/logo.png" className='w-[100px] mx-auto' alt="Nugget" />
        </div>

        <div className="socials flex gap-5 justify-center items-center font-light uppercase text-lg mt-5">
            <a href="#" className='underline'>Socials:</a>
            <a href="https://t.me/Nuggetonsol">Telegram</a>
            <a href="https://x.com/Nugget_on_sol?t=PaCyAhJu03REa2Y9-3412g&s=09">X</a>
            <a href="#">Intagram</a>
            <a href="#">Discord</a>
        </div>

        <div className="wrap mt-16 text-center">
            <p className='uppercase font-xl'>A solana memecoin</p>
            <h1 className='lg:text-8xl text-4xl font-extrabold my-5 mt-3 font-jumb'>Cute Nugget Coin</h1>
            <span className='italic'>Cute Nugget, here to consume all other memes in his bid to become the most powerful ancient one.</span>
        </div>

        <div className="btn-wrap text-center my-10 ">
            <button className='p-4 px-7 border border-black font-jumb text-amber-800'>Buy Now</button>
        </div>

        <div className="wrap bg-white py-5 my-5">
        <img src="/asset/nugg.png"  alt="nugget" className=' lg:w-[50%] mx-auto' />
        </div>
    </div>
  )
}
